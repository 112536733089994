import React from 'react'
import * as heroStyles from './hero.module.css'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Hero = (props) => {
    const data = useStaticQuery(query)
    const pics = data.contentfulHero.heroImages
    let image
    pics.forEach((pic) => {
        if (pic.title === props.imgurl) {
            image = getImage(pic)
        }
    })

    return (
        <div className={heroStyles.hero}>
            <GatsbyImage image={image} alt='hero' className={heroStyles.imgcontainer} />
            <h1 className={heroStyles.herotext}>{props.name}</h1>
        </div>
    )
}

export const query = graphql`
  {
    contentfulHero {
      heroImages {
        gatsbyImageData
        title
      }
    }
  }
`

export default Hero