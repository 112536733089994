import React, { useState } from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import SEO from '../components/SEO'
import * as contactStyles from './contact.module.css'
import { FaEnvelope } from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa';
import { FaMapPin } from 'react-icons/fa';

const Contact = () => {
    const [state, setState] = useState({})

    const encode = (data) => {
        return Object.keys(data)
          .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
          .join('&')
      }

    const handleChange = (e) => {
      setState({ ...state, [e.target.name]: e.target.value })
    }
  
    const handleSubmit = (e) => {
      e.preventDefault()
      const form = e.target
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...state,
        }),
      })
        .then(() => navigate(form.getAttribute('action')))
        .catch((error) => alert(error))
    }

    return (
        <Layout>
            <SEO title='Kontakt | Poolusfilm' description='Kirjuta meile!'/>
            <Hero name='KONTAKT' imgurl='kontaktHero' imgpos='0px -259px'/>
            <main className={contactStyles.page}>
                <section className={contactStyles.contactpage}>
                    <div className={contactStyles.infoarea}>
                        <h2>Võta meiega ühendust!</h2>
                        <p>Võid meile helistada või kirjutada igal ajal! Vastame teile koheselt kui võimalik!</p>
                        <ul className={contactStyles.contactslist}>
                            <li>
                                <a href='mailto:poolusfilm@gmail.com'><FaEnvelope className={contactStyles.contact} />
                                poolusfilm@gmail.ee
                                </a>
                            </li>
                            <li>
                                <FaPhone className={contactStyles.contact} />
                                51925191
                            </li>
                            <li>
                                <FaMapPin className={contactStyles.contact} />
                                Tallinn / Ida-Virumaa
                            </li>
                        </ul>
                    </div>
                    <div>
                        <form name='contact' method='POST' action='/saadetud' className={contactStyles.contactform} data-netlify='true' onSubmit={handleSubmit}>
                            <div className={contactStyles.formrow}>
                               <label htmlFor='name'>Teie Nimi</label>
                               <input type='text' name='name' id='name' required onChange={handleChange}/>
                            </div>
                            <div className={contactStyles.formrow}>
                               <label htmlFor='email'>Teie Email</label>
                               <input type='text' name='email' id='email' required onChange={handleChange}/>
                            </div>
                            <div className={contactStyles.formrow}>
                               <label htmlFor='title'>Pealkiri</label>
                               <input type='text' name='title' id='title' onChange={handleChange}/>
                            </div>
                            <div className={contactStyles.formrow}>
                               <label htmlFor='message'>Teie Sõnum</label>
                               <textarea name='message' id='message' required onChange={handleChange}></textarea>
                            </div>
                            <div className={contactStyles.formrow}>
                                <button type='submit' >Saada Kiri</button>
                            </div>
                        </form>
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default Contact